enum en
{
    textManagemet = 'Text management',
    userManagement = 'User management',
    AddRegulatoryText = 'Add regulatory text',
    editRegulatoryText = 'Edit regulatory text',
    search = 'Search',
    theme = 'Theme',
    domain = 'Domain',
    subDomain = 'Sub-Domain',
    text = 'Text',
    select = 'Select',
    yourText = 'Your text',
    result = 'Result',
    dateOfText = 'Date of text',
    updatedAt = 'Updated at',
    updatedBy = 'Updated by',
    priority = 'Priority',
    update = 'Update',
    NoDataToDisplay = 'No data to display',
    LoadingDataPleaseWait = 'Loading data. Please wait',
    fieldApplication = 'Field Application',
    title = 'Title',
    status = 'Status',
    defaultApplicability = 'Default Applicability',
    country = 'Country',
    confidential = 'Confidential',
    comment = 'Comment',
    keyDates = 'Key dates',
    dateOfLastModification = 'Date of last modification',
    textModifier = 'Text Modifier',
    siteAssignment = 'Site assignment',
    sitesAssociated = 'Associated sites',
    SitesNotAssociated = 'Not associated sites',
    reference = 'Reference',
    applicability = 'Applicability',
    wording = 'Wording',
    titleOfCompliance = 'Title of compliance',
    titleOfText = 'Title of text',
    addASection = 'Add a section',
    editSection = 'Edit a section',
    edit = 'Edit',
    add = 'Add',
    delete = 'Delete',
    link = 'Link',
    synthesis = 'Synthesis',
    addACompliance = 'Add a compliance',
    editCompliance = 'Edit a compliance',
    order = 'Order',
    AreYouSureYouWantToDelete = 'Are you sure you want to delete?',
    addUser = 'Add user',
    editUser = 'Edit user',
    firstname = 'First name',
    lastname = 'Last name',
    email = 'Email',
    department = 'Department',
    details = 'Details',
    fixedTel = 'Landline telephone',
    or = 'or',
    deleted = 'Deleted',
    cancel = 'Cancel',
    next = 'Next',
    save = 'Save',
    parameters = 'Parameters',
    language = 'Language',
    validityEndDate = 'Validity end date',
    creationDate = 'Created At',
    createdBy = 'Created by',
    modificationDate = 'Modification date',
    roleBySite = 'Role by Site',
    statistics = 'Statistics',
    lastConnection = 'Last connection',
    firstConnection = 'First connection',
    numberOfConnection = 'Number of connexion',
    averageTimePerConnection = 'Average time per connection',
    connected = 'Connecté',
    customerManagement = 'Customer management',
    CopyTheBase = 'Copy the base',
    addACustomer = 'Add a customer',
    siteManagement = 'Site management',
    addASite = 'Add a site',
    developAll = 'Develop all',
    cutThroughAll = 'Cut through all',
    parentSite = 'Parent site',
    informationsOnTheSite = 'Informations of the site',
    address = 'Adress',
    additionalAddress = 'Additional Address',
    zipCode = 'Zip code',
    city = 'City',
    phoneNumber = 'Phone number',
    group = 'Group',
    LambertCoordinates = 'Lambert coordinates',
    listOfTexts = 'List of texts',
    modify = 'Modify',
    managingEvaluations = 'Evaluation managing',
    progressRate = 'Progress rate',
    complianceRate = 'Compliance rate',
    transversalAssessment = 'Transversal evaluation',
    textEvaluation = 'Text evaluation',
    textOfCompliance = 'Text of compliance',
    evaluation = 'Evaluation',
    evaluations = 'Evaluations',
    valuationDate = 'Valuation date',
    historic = 'Historic',
    customerAnnotation = 'Customer annotation',
    novalliaAnnotation = 'Novallia annotation',
    complianceAnnotation = 'Compliance annotation',
    ValuedBy = 'Valued by',
    SynthPlug = 'Synth plug',
    AppendicesAndIllustrationsFile = 'Attached and Illustrations File',
    supportingDocuments = 'Attached documents',
    titleRequired = 'Title is required',
    statusRequired = 'Status is required',
    documentTypeRequired = 'Type document is required',
    subDomainRequired = 'Sub domain is required',
    firstNameRequired = 'First name is required',
    lastNameRequired = 'Last name is required',
    emailRequired = 'Email is required',
    departmentRequired = 'Department is required',
    positionRequired = 'Position is required',
    fixedTelRequired = 'Fixed telephone is required',
    mobileRequired = 'Mobile is required',
    addressRequired = 'Address is required',
    codeRequired = 'Code is required',
    referenceRequired = 'Reference is required',
    orderRequired = 'Order is required',
    priorityRequired = 'Priority is required',
    startDateRequired = 'Start date is required',
    endDateRequired = 'End date is required',
    revisedEndDateRequired = 'Revised end date is required',
    responsibleAPRequired = "The action plan coordinator  is required",
    statusEvaluationRequired = "Evaluation status is required",
    commentRequired = "Comment is required",
    urlRequired = "URL is required",
    userHasSuccessfullyModified = 'User has been successfully modified',
    userHasSuccessfullyAdded = 'User has been successfully added',

    userShouldAnserToQuiz = 'Please note that you will have to answer (yes or no) to all the questions. However, you can recall the questionnaire and correct it if necessary.',
    userHasNotAnserToAllQuiz = 'Please answer any missing questions before submitting the form.',
    userSaveQuiz = 'The questionnaire was well taken into account. The audit grid will be updated accordingly.',
    userHasSuccessfullyDeleted = 'User has been successfully deleted',
    rowReordered = 'Rows Reordered',
    documentHasSuccessfullyAdded = 'document has been successfully added',
    documentHasSuccessfullyModified = 'document has been successfully modified',
    complianceHasSuccessfullyAdded = 'Compliance has been successfully added',
    ComplianceHasSuccessfullyModified = 'Compliance has been successfully modified',
    ComplianceHasSuccessfullyDeleted = 'Compliance has been successfully deleted',
    sectionHasSuccessfullyAdded = 'Section has been successfully added',
    SectionHasSuccessfullyModified = 'Section has been successfully modified',
    SectionHasSuccessfullyDeleted = 'Section has been successfully deleted',
    fileHasSuccessfullyDeleted = 'File has been successfully deleted',
    actionPlanHasSuccessfullyDeleted = 'Action plan has been successfully deleted',
    taskAPHasSuccessfullyDeleted = 'Action plan task has been successfully deleted',
    commentAPHasSuccessfullyDeleted = 'Action plan comment has been successfully deleted',
    customerHasSuccessfullyAdded = 'Customer has been successfully added',
    customerHasSuccessfullyModified = 'Customer has been successfully modified',
    siteHasSuccessfullyAdded = 'Site has been successfully added',
    siteHasSuccessfullyModified = 'Site has been successfully modified',
    evaluationHasSuccessfullyAdded = 'Evaluation has been successfully added',
    evaluationHasSuccessfullyModified = 'Evaluation has been successfully modified',
    actionPlanHasSuccessfullyAdded = 'Action plan has been successfully added',
    siteAccessDeniedMsg = 'You do not have access to this action plan. Please check your credentials',
    paAccessDeniedMsg = 'You do not have access to this action plan',
    paNotFoundMsg = 'The requested action plan could not be found',
    actionPlanHasSuccessfullyModified = 'Action plan has been successfully modified',
    exportWasSuccessfullySent = 'The requested file will be sent to you by email',
    importWasSuccessfullySent = 'Document sent successfully',
    importFileWasSuccessfullySent = 'file sent successfully',
    taskHasSuccessfullyAdded = 'Task has been successfully added',
    taskHasSuccessfullyModified = 'Task has been successfully modified',
    commentHasSuccessfullyAdded = 'Comment has been successfully added',
    commentHasSuccessfullyModfied = 'Comment has been successfully modified',
    fileSuccessfullyUploaded = 'File successfully uploaded',
    password = 'Password',
    confirmPassword = 'Confirme password',
    passwordDidNotMatch = 'Passwords did not match',
    validatePassword = 'Password must include a minimum of 8 characters including uppercase, lowercase, numbers and special characters',
    resetPassword = 'Reset the password',
    customer= 'Customer',
    invalidMail = 'Invalid email address',
    emailUsed = 'Email: This value is already used',
    accessRights = 'Access rights',
    userRole = 'User Role',
    activate = 'Activate',
    searchByName = 'Search by name',
    searchByTheme = 'Search by Theme',
    searchByDomain = 'Search by domain',
    searchBysubDomain = 'Search by sub domain',
    requirementEvaluated = ' compliance to be evaluated',
    requirementsEvaluated = ' compliances to be evaluated',
    requirementReEvaluated = ' compliance to be re-evaluated',
    requirementsReEvaluated = ' compliances to be re-evaluated',
    onlineAssistance = 'Online assistance',
    selectedDocuments = 'selected document(s)',
    confirm = 'Confirm',
    importSites = 'Import sites',
    import = 'Import',
    importDocument = 'Import textes',
    importCompliance = 'Compliance import',
    importSection = 'Import  sections',
    importAP = 'Action plan import',
    assignDocuments = 'Document Assignment',
    importSiteDocument = 'An import for this document is in progress. Thank you for your patience',
    complianceFile = "Compliance file (xlsx)",
    send = "Send",
    copyBase = "Copy base",
    documentFile = "Textes file (xlsx)",
    sectionsFile = "Sections file (xlsx)",
    actionPlanFile = "Action plan file (xlsx)",
    personalisedBase = "Personalised base",
    actionPlan = "Action plan",
    actionsPlans = "Actions plans",
    attachActionPlan = "Attach an action plan",
    addActionPlan = "Add of an AP",
    apPilot = "Action Plan Pilot",
    responsibleAP = "Responsible for the action plan",
    typeProgresion = "Type of progression ",
    rateProgress = "Rate of progress",
    startDate = "Start date",
    endDate = "End date",
    revisedEndDate = "Revised end date",
    fixedReminder = "Fixed reminder",
    nbDaysBeforeAPDeadline = "Number of days before the AP deadline",
    periodicRelaunch = "Periodic reminder",
    periodicReminderTime = "Periodic reminder time",
    divers= "Others",
    tasks= "Tasks",
    archivingVisitReports = "Archiving of visit reports",
    compliance = "Compliance",
    addingTasks = "Adding a tasks",
    addingComments = "Add a comment",
    responsibleTasks = "Responsible for the tasks",
    risksopportunities = "Risks and opportunities",
    process = "Process",
    measureEffectiveness = "Measure of effectiveness",
    verificationType = "Verification type",
    responsibleVerification = "Verification Responsible",
    verificationDate = "Verification date",
    resources = "Resources",
    financialRsources = "Financial resource",
    operationalResource = "Pperational resource",
    technologicalResources = "Technological resource",
    humanResources = "Human resource",
    thematic = "Thematic",
    entitled = "Entitled",
    compliances = "Compliances",
    comments = 'Comments',
    importSectionCompliance = 'A compliance import for this document is in progress. Thank you for your patience',
    importActionPlan = 'An action plan import  is in progress. Thank you for your patience',
    hideInAP = 'Disable action plan',
    listApplicableTexts = 'List of applicable texts',
    reportsActionPlan = 'Reports on actions plan',
    report = 'Report',
    listActionPlanTasks = 'List of actions plan and tasks',
    listActionPlanCompliance = 'List of actions plan and List of compliances to be reassessed',
    listOfActionsPlans = 'list of actions plans',
    listNonConformitiesWithoutCompletedAP = 'List of non-conformities without completed actions plan',
    expiredAP = 'Expired actions plan',
    listEvaluations= 'List of evaluations',
    statisticsEvaluations= 'Statistics of evaluations',
    reportOnComplianceStatus = 'Reports on compliance status',
    nbCompliances = 'Number of compliances',
    indicators = 'Indicators',
    indicatorsRte = 'RTE Indicators',
    attachedActionPlan = 'Attached action plan',
    listOfRequirementReassess = 'List of requirements to reassess.',
    name = 'Name',
    transversalEvaluation = 'Transversal evaluations',
    successfullyUpdated = 'Successfully updated',
    quiz  = 'Quiz',
    seeAllMessages = 'See all messages',
    yourMessage = 'Your message',
    titleOfDiscussion = 'Title of discussion',
    SubjectOfDiscussion = 'Subject of discussion',
    lastMessage = 'Last message',
    discussionLaunchedOn = 'Discussion launched on',
    dateOfLastMessage = 'Date of last message',
    numberOfMessage = 'Number of messages',
    StatusReadUnread = 'Status (Read / Unread)',
    importFiles = 'Import files',
    year = 'Year',
    localTextRte = 'local text (RTE)',
    currentTasks = 'Current taks',
    user = 'User',
    DateOfLastQuestionnaireModification = 'Last modified was  on',
    by = 'By',
    desaffecterDocument = 'Desattribute selected',
    selectionDesatributeSuccess = 'The selection has been successfully deattributed',
    aucunSelectDocument  = 'You have not selected any document yet ',
    idOfDocument = 'Id of document',
    copyOfAssociatedSite = 'Copy of associated sites',
    AreYouSureYouWantToShowSites = 'Are you sure you want to load affected sites?',
    rememberSaveAffectSite = 'Remember to save to affect sites',
    loadSites = 'Load sites',
    documentHasSuccessfullyDeleted = 'Document has been successfully deleted',
    documentassignmentsAreYouWantDelete = 'This document has assignments are you sure you want to delete?',
    siteassignmentsAreYouWantDelete = 'This site has assignments are you sure you want to delete?',
    siteHasSuccessfullyDeleted = 'Site has been successfully deleted',
    doNotHaveRightDeleteEntity = 'You do not have the right to delete this entity.',
    updateSuccessfullyCompleted = 'The update was successfully completed.',
    importPaRte = "Import of the PA NVsite.",
    lignes = "lines",
    ligne = "line",
    textes = "textes",
    texte = "texte",
    customerHasSuccessfullyDeleted = 'Customer has been successfully deleted',
    fileHasBeenUploadedToCompliance = 'The file has been uploaded to the compliance',
    fileHasBeenUploadedToActionPlan = "The file has been uploaded to the action plan",
    anErrorOccurredWhileUploadingFileToActionPlan = "An error occurred while uploading the file to the action plan",
    anErrorOccurredWhileAddingActionPlan = "An error occurred when adding an action plan",
    currentStatus = 'Current status',
    madeBy = 'Made by',
    profilePhotoHasBeenUploaded = 'Profile photo has been uploaded',
    fileRequired = 'File is required',
}
export {en};
