<template>
	<div>

		<div :class="['profile', {'profile-expanded': expanded}]" class="p-link fuse-vertical-navigation">
<!--				<img alt="Profile" class="profile-image" src="layout/images/avatar.png"/>-->
      <Avatar  :image="avatarUrl" class="mr-2" size="large" shape="circle" v-if="avatarUrl"></Avatar>
      <Avatar :label="avatarUser" class="mr-2" size="large" shape="circle" v-else></Avatar>
        <div class="grid align-items-center pt-4">

          <div>
            <span class="profile-name">{{user.name}} {{user.surname}} </span>
          </div>
          <button class="btn-chevron" @click="onClick"> <i class="pi pi-fw pi-chevron-down"></i></button>
        </div>
		</div>

		<transition name="layout-profile-menu">
			<ul class="layout-menu profile-menu" v-show="expanded">
				<li role="menuitem">
					<a href="javascript:void(0)"  @click="profile" :tabIndex="expanded ? null : '-1'">
						<i class="pi pi-user"></i>
						<span>Profil</span>
					</a>
					<div class="layout-menu-tooltip">
						<div class="layout-menu-tooltip-arrow"></div>
						<div class="layout-menu-tooltip-text">Mon profil</div>
					</div>
				</li>
<!--				<li role="menuitem">-->
<!--					<a href="#" :tabIndex="expanded ? null : '-1'">-->
<!--						<i class="pi pi-lock"></i>-->
<!--						<span>Privacy</span>-->
<!--					</a>-->
<!--					<div class="layout-menu-tooltip">-->
<!--						<div class="layout-menu-tooltip-arrow"></div>-->
<!--						<div class="layout-menu-tooltip-text">Privacy</div>-->
<!--					</div>-->
<!--				</li>-->
<!--				<li role="menuitem">-->
<!--					<a href="#" :tabIndex="expanded ? null : '-1'">-->
<!--						<i class="pi pi-cog"></i>-->
<!--						<span>Settings</span>-->
<!--					</a>-->
<!--					<div class="layout-menu-tooltip">-->
<!--						<div class="layout-menu-tooltip-arrow"></div>-->
<!--						<div class="layout-menu-tooltip-text">Settings</div>-->
<!--					</div>-->
<!--				</li>-->
				<li role="menuitem">
					<a href="#" @click="logout" :tabIndex="expanded ? null : '-1'">
						<i class="pi pi-sign-out"></i>
						<span>Se déconnecter</span>
					</a>
					<div class="layout-menu-tooltip">
						<div class="layout-menu-tooltip-arrow"></div>
						<div class="layout-menu-tooltip-text">Logout</div>
					</div>
				</li>
			</ul>
		</transition>
	</div>
</template>

<script>
import {Actions} from "@/store/enums/StoreEnums";
import router from "@/router";
import store from "@/store";
import {useStore} from "vuex";
import {computed} from "vue";
import UserService from "@/service/UserService";

export default {
	emits: ['profile-click'],
	props: {
		expanded: {
			type: Boolean,
			default: null,
      avatarUrl: null,
		}
	},
  data(){
    return{
      avatarUser:'',
    }
  },
  setup(){
    const store = useStore();

    const user = computed(() => {
      return store.getters.currentUser
    });

    // const user = store.state.AuthModule.user;
    return {store, user};
  },
  watch: {
    user() {
      this.getDetailsUser()
      this.getProfilePicture()
    }
  },

  mounted() {

    // this.store.dispatch(Actions.GET_USER).then(data=>{
    // })
    this.getProfilePicture()
    this.getDetailsUser()
  },
  methods: {
    getDetailsUser(){
      if (this.user.name && this.user.surname){
        this.avatarUser= this.user.name[0] + ' '+ this.user.surname[0]
      }
    },
		onClick(event) {
			this.$emit('profile-click', event);
			event.preventDefault();
		},
    logout(){

      store
          .dispatch(Actions.LOGOUT)
          .then(() => {
            router.push({name: "login"})
            UserService.destroyRoleUser()

            UserService.destroyApplicationMaster()
            this.$store.commit('setIsInitSiteRte', false)
          });

    },
    profile(){
      router.push({name:"profile"});
    },
    getProfilePicture() {
      this.store.dispatch(Actions.IMAGE_MEDIA, {'customHeader': true}).then((data, filename) => {
        if (data){
          const url = window.URL.createObjectURL(data, filename);
          this.avatarUrl = url
        }

      })
    },
	},
	name: "AppInlineProfile"
}
</script>

<style scoped>

</style>
